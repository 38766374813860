.btn-primary {
  background-color: #9a2b59;
  border-color: #9a2b59;
}

.btn-primary-sec {
  color: white;
  background-color: #585858;
  border-color: #585858;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  color: #fff;
  background-color: #7c2248;
  border-color: #7c2248;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #7c2248;
  border-color: #7c2248;
}

.btn-primary-sec:hover,
.btn-primary-sec:focus,
.btn-primary-sec:active {
  color: #fff;
  background-color: #383838;
  border-color: #383838;
}

.btn-primary-sec:not(:disabled):not(.disabled):active,
.btn-primary-sec:not(:disabled):not(.disabled).active,
.show > .btn-primary-sec.dropdown-toggle {
  color: #fff;
  background-color: #383838;
  border-color: #383838;
}
