@media screen and (max-width: 768px) {
    h5 {
        font-size: 18px;
    }

    label {
        font-size: 14px;
    }

    p {
        font-size: 14px;
    }

    .swal2-popup {
        width: 400px;
    }

    .swal2-title {
        font-size: 20px;
    }

    .swal2-icon {
        width: 70px;
        height: 70px;
    }
}