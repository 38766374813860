.indexedStep {
  color: white;
  width: 40px;
  height: 40px;
  font-size: 12px;
  /* background-color: rgba(211, 211, 211, 0.8); */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #4a154b;
  border: 3px solid;
}

@media screen and (max-width: 768px) {
  .indexedStep {
    color: white;
    width: 25px;
    height: 25px;
    font-size: 12px;
    /* background-color: rgba(211, 211, 211, 0.8); */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: #4a154b;
    border: 3px solid;
  }

  .CheckMark {
    font-size: 17px !important;
    margin: auto !important;
  }
}

.indexedStep.accomplished {
  background-color: white;
  border-color: #4a154b;
}

.CheckMark {
  font-size: 26px;
  font-weight: 600;
  color: #4a154b;
  -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
  -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
  transform: scaleX(-1) rotate(-46deg);
}

.indexedStep.null {
  border-color: #4a154b;
  color: #f6edf6;
  background-color: white;
  font-size: 18px;
}

.RSPBprogressBar {
  height: 8px !important;
}

.stepContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
