.container {
  text-align: center;
  margin-top: 30px;
}

.container button {
  padding: 10px 20px;
  background-color: inherit;
  border: 1px solid gray;
  margin-bottom: 10px;
}

.container button:hover {
  background-color: gray;
  color: white;
}

.modalContainer {
  position: fixed;
  width: 100vw;
  height: 100%;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.modals {
  width: 90%;
  max-width: 500px;
  border: 5px;
  padding: 10px;
  background-color: white;
  box-sizing: border-box;
}

.modal__bottom {
  text-align: right;
  margin-top: 10px;
}

.modal__bottom button {
  padding: 6px 10px;
}

.sigPadContainer {
  background-color: rgb(238, 235, 235);
  padding: 0 10px 10px;
}

.sigPadContainer hr {
  margin-bottom: unset;
  background-color: gainsboro;
}

.sigCanvas {
  width: 100%;
  height: 200px;
  z-index: 2;
}
.react-pdf__Page__textContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Disable pointer events on the text layer to allow interaction with elements beneath */
  z-index: 1; /* Lower z-index for the text layer */
}
.sigPadContainer button {
  border: none;
  margin-left: auto;
  color: rgb(78, 75, 75);
  padding: 0;
  display: block;
  margin-top: 5px;
}

.sigPadContainer button:hover {
  background-color: unset;
  color: black;
}

.sigPad__penColors {
  margin-bottom: 10px;
}

.sigPad__penColors p {
  display: inline-block;
  margin-right: 5px;
}

.sigPad__penColors span {
  padding: 0px 9px;
  border-radius: 100%;
  margin-right: 5px;
}

.modal__bottom .create {
  margin-left: 10px;
  /* background-color: rgb(126, 119, 119); */
  color: black;
}

.signature {
  width: 200px;
  height: 70px;
  margin-top: 20px;
  box-shadow: 0 0 4px 2px gainsboro;
  padding: 10px;
  object-fit: contain;
}

.iframe {
  margin: 0;
  border: 0;
  width: 100%;
  height: 800px;
}

.pdf-container {
  height: 600px; /* Set a maximum height for larger screens */
  overflow: scroll;
  /* position: relative; */
  max-width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
}

.zoom-controls {
  /* Position the zoom controls to the top-right corner */
  /* position: sticky; */
  top: 10px;
  right: 10px;
  margin-bottom: 5px;
}

.zoom-button {
  padding: 8px 12px;
  margin-right: 8px;
  background-color: #007BFF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.zoom-button:hover {
  background-color: #0056b3;
}

/* Apply responsive styles for smaller screens */
@media (max-width: 767px) {
  .pdf-container {
    max-height: none; 
    overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: auto; /* Enable vertical scrolling *//* Remove max height for smaller screens */
  }

  .zoom-controls {
    position: sticky; /* Remove absolute positioning for smaller screens */
    margin-top: 10px; /* Add top margin to separate from the PDF */
    text-align: center; /* Center-align zoom controls */
  }
  .zoom-button {
    padding: 5px 5px; /* Adjust padding for mobile */
    font-size: 12px; /* Adjust font size for mobile */
  }
  .container button {
    padding: 5px 5px;
    background-color: inherit;
    border: 1px solid gray;
  }
  .pdf-page {
    margin-bottom: 5px;
    background-color: cadetblue; /* Add the desired space between pages here */
  }
  .pdf-background {
    background-color: gray; /* Set the desired gray background color here */
    padding: 10px; /* Add padding if needed */
  }
}
